import { CustomerMainComponent } from "../components/CustomerMainComponent";

export class ObserverModel {


    id: number = 0;
    context: any | undefined;

    public update(suppliedContext) {
        this.context = suppliedContext;
        (window as any).CustomerMainComponent.receiveNotification_SfCustomer(suppliedContext)
    } 

    subscription(suppliedContext) {
        return new Promise<any>(
            function (resolve, reject) {
                if (suppliedContext !== undefined) {

                    resolve(suppliedContext); // fulfilled
                } else {
                    var reason = new Error('error occured');
                    reject(reason); // reject
                }

            }
        );
    }
    notifyThePromise(suppliedContext) {
        if (suppliedContext !== null) {
            this.subscription(suppliedContext)
                .then(function (result) {
                    // yay, you got a new phone
                    console.log(result);
                    //this.context = result;
                    // output: { brand: 'Samsung', color: 'black' }
                    //CustomerMainComponent.receiveNotification(result);
                })
                .catch(function (error) {
                    // oops, mom don't buy it
                    console.log(error.message);
                    // output: 'mom is not happy'
                });
        }

    }
}